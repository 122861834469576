import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Meta from '../components/meta'
import Layout from '../components/layout'

class TermsOfUse extends React.Component {
  render() {
    const site = get(this, 'props.data.site')

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Meta
            site={site}
          />
          <article className="wrapper article">
            <h1>利用規約</h1>
            <p>
              Tech lib（以下、「本サービス」といいます）はサークルアラウンド株式会社（以下、「当社」といいます）が提供するサービスです。
              本規約は、当社がWebサイト「Tech lib（ techlib.circlearound.co.jp ）」において提供するサービスを利用される方すべて（以下、「ユーザー」）について、本サービスの利用を開始した時点で適用されます。
</p>

            <h2>1. 免責</h2>
            <p>
              当社は、本サービスにより提供される記事、図表、動画、写真およびソースコードやソフトウェア等（以下、「コンテンツ」）
              が適切であること、ユーザーの需要に適合すること、本サービス自体の完全性、その他ユーザーへの本サービスの提供については最善を尽くしますが、
              ユーザーに対しこれらについて何ら保証するものではありません。当社は、ユーザーが本サービスを利用したことにより被った損害、
              その他本サービスに関連して被った損害について責任を免れるものとします。
              本サービスで提供された内容をユーザーが自身で試行した事や、それを第三者へ提供した事などによって起こる損害についても含めて免れるものとします。
</p>

            <h2>2. 禁止事項</h2>
            <p>
              当社は、本サービスにおいて、ユーザーが次の事項に該当する、もしくはその恐れのある行為を行うことを禁止しています。
</p>

            <ul>
              <li>当社を装う、または詐称する行為</li>
              <li>当社のサーバまたは本サービスに対する不正アクセス等、本サービスの運営に支障を生じさせる行為</li>
              <li>本サービスに関するプログラム等の改変、リバースエンジニアリング、解析またはユーティリティの作成・頒布等の行為</li>
              <li>本サービスを利用した、営業活動または営利もしくはその準備を目的とした行為</li>
              <li>当社または他のユーザーその他第三者の財産、プライバシーまたは肖像権を侵害する行為</li>
              <li>当社または他のユーザーその他第三者の著作権その他の知的財産権を侵害する行為</li>
              <li>犯罪行為を目的にしたり、犯罪行為を誘発する行為</li>
              <li>他人の名誉を毀損したり、権利を侵害する行為</li>
              <li>反社会的勢力に直接・間接に利益を提供する行為</li>
              <li>その他、法令に違反し、公序良俗に反する行為</li>
              <li>本サービスの運営を妨げるような行為</li>
              <li>その他当社が不適切と判断する一切の行為</li>
            </ul>

            <h2>3. 著作権等</h2>
            <p>
              コンテンツに関する著作権および商標権その他知的財産権は、当社または当該情報の提供元に帰属します。
              また、本サービスに関する著作権および商標権その他知的財産権についても、当社に帰属します。
</p>

            <h2>4. 提供範囲</h2>
            <p>本サービスは日本語を解する方に向けた日本国内向けのサービスであることをここに明記します。
              その他海外の法令に照らし合わせた利用を想定していませんので、該当しない方は利用を中断してください。</p>

            <h2>5. 規約違反</h2>
            <p>当社は、ユーザーが本規約等に違反した場合には、当該ユーザーによる本サービスの利用を停止および禁止することができるものとします。
              また、当該ユーザーによる違反行為により当社が損害を被った時は、当社は、当該ユーザーに対し、当該違反行為によって当社が被った損害額を請求することができるものとします。</p>

            <h2>6. 本サービス内のリンク等について</h2>
            <p>
              本サービスにおいて他のWebサイトやリソースへのリンクを提供している場合があります。
              当該サイトやリソースについては、それぞれの提供者の課す権利や義務に従って適切に利用してください。
              また、当社は当該サイトやリソースの正確性、信憑性などを何ら保証するものではありません。
</p>

            <h2>7. 転載について</h2>
            <p>
              ユーザーは、本サービスにより提供されるコンテンツの転載を希望する場合は、当社の許可を受ける必要があります。
              本サービスにより提供される記事や写真・図表等の無断転載は禁止します。
              ただし引用については法令の認める方法に基づきその範囲内において行う事が許可されます。
</p>

            <h2>8. 規約の変更</h2>
            <p>当社は、当社が必要と判断したときには、ユーザーの事前の承諾を得ることなく、本規約等を変更できるものとします。
              当社は、本規約等変更後に、ユーザーが本サービスを利用したことにより、ユーザーが変更後の本規約等の内容を承諾したものとみなします。
              ユーザーは変更後の本規約の適用について異議ある場合は、本サービスを利用しないこととします。
</p>

            <h2>9. サービス内容の変更や停止</h2>
            <p>当社はユーザーに事前に通知することなく本サービスの内容を変更し、また提供を停止または中止することができるものとします。</p>

            <h2>10. プライバシーポリシー</h2>
            <p>当社が本サービスを通じて取得したユーザーの個人情報およびサービスの利用状況に関する情報については、
              当社が規定する<a href="https://circlearound.co.jp/privacy/" target="_blank" rel="noreferrer">プライバシーポリシー</a>および個人情報保護法等法令の規定に準拠して取り扱うものとします。</p>

            <h2>11. 法令等</h2>
            <p>本規約等は日本国の法令に準拠し、解釈されるものとし、本サービスおよび本規約等に関する紛争は、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>

            <p>
              改訂日　2020年10月10日
</p>


          </article>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query TermsOfUseQuery {
    site {
      siteMetadata {
        title
        tagline
        author
        host
        description
      }
    }
  }
`

export default TermsOfUse

